<template>
  <dialog-outline
    v-model="showDialog"
    max-width="35%"
    :icon-color="criticalColorClass"
    :title-color="criticalColorClass"
  >
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <slot name="preContext"></slot>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- Source -->
      <batch-records-table
        :record-list="recordList"
        :selected-record="isSelectedRecord"
        :records-status="batchOperationStatus"
      >
        <template v-if="destinationRecord">
          v-slot:subHeader>
          <v-subheader>
            Records
          </v-subheader>
        </template>
      </batch-records-table>

      <v-divider v-if="destinationRecord" class="mt-3 mb-2"></v-divider>

      <!--  Destination -->
      <batch-records-table
        v-if="destinationRecord"
        :record-list="[destinationRecord]"
        :records-status="[defaultDestinationOperationStatus]"
      >
        <template v-if="destinationRecord" v-slot:subHeader>
          <v-subheader>
            Destination
          </v-subheader>
        </template>
      </batch-records-table>

      <slot name="moreContext"></slot>

      <!-- display Progress -->
      <progress-display v-if="visibleProgress">
        {{ progressText }}
      </progress-display>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actionsLeft>
      <base-button
        @click="onCancel"
        v-if="batchPerformed"
        :disabled="batchCompleted"
      >
        {{ dialogCancelCommandLabel }}
      </base-button>
    </template>

    <template v-slot:actions>
      <base-button
        @click="onOk"
        :color="criticalColor"
        :disabled="batchPerformed"
      >
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Close Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeAction()">
        <template v-slot:default>
          {{ dialogCloseCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { batchRecordStatus, recordOperation } from "@/model/record/recordModel";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import { colorTheme, getErrorColor } from "@/design/colors/Color";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "BatchDialog",
  components: {
    BatchRecordsTable: () =>
      import("@/components/shared/core/batch/BatchRecordsTable")
  },
  mixins: [dialogOutlineMixin, progressAbleMixin],

  props: {
    recordList: {
      type: Array,
      default: () => []
    },
    destinationRecord: {
      type: Object,
      default: undefined
    },
    isSelectedRecord: {
      type: Object,
      default: undefined
    },
    recordOperation: undefined,
    recordOperationLabel: undefined,
    recordOperationIcon: undefined,
    batchOperationStatus: undefined,
    alert: undefined,
    progress: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      batchPerformed: false,
      isCanceled: false,
      batchCompleted: false
    };
  },

  computed: {
    /**
     * Move Record Operation
     * @return {{name: string, icon: string, label: string}} Move Record Operation
     */
    operation() {
      return this.recordOperation;
    },

    /**
     * Copy Record Operation Label
     * @return {string} Copy Record Operation Label
     */
    operationLabel() {
      return this.recordOperationLabel
        ? this.recordOperationLabel
        : this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.recordOperationIcon
        ? this.recordOperationIcon
        : this.operation?.icon;
    },

    /**
     * Get Copy Record Dialog title
     * @return {string} Copy Record Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} Records`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Default Destination Operation Status for Move Operation
     * @return {{recordId: *, status: string}}
     */
    defaultDestinationOperationStatus() {
      return {
        recordId: this.destinationRecord?.id,
        status: batchRecordStatus.Ready
      };
    },

    /**
     * get Color Class
     * @return {Color}
     */
    criticalColorClass() {
      return this.recordOperation?.name === recordOperation.Delete ||
        this.recordOperation?.name === recordOperation.Purge
        ? getErrorColor()
        : undefined;
    },

    /**
     * Get Critical Color
     * @return {string|undefined}
     */
    criticalColor() {
      return this.recordOperation?.name === recordOperation.Delete ||
        this.recordOperation?.name === recordOperation.Purge
        ? colorTheme.error
        : undefined;
    }
  },

  methods: {
    /**
     *  close Dialog
     */
    closeAction() {
      this.closeDialog();
      this.$emit(eventNames.close);
    },

    /**
     * On Ok (Action Performed)
     */
    onOk() {
      this.batchPerformed = true;
      this.$emit(eventNames.onOk);
    },

    /**
     * on Cancel Button Clicked
     */
    onCancel() {
      this.$emit(eventNames.dialogCancel);
    }
  },

  watch: {
    async showDialog(value) {
      if (value) {
        this.batchPerformed = false;
        this.$emit(eventNames.onDialogOpened);
      } else {
        if (this.batchPerformed) {
          this.$emit(eventNames.onDialogClosedAfterAction);
        }
      }
    },

    alert(value) {
      this.internalAlert = value;
      if (this.internalAlert?.type === this.alertTypeName?.success)
        this.batchCompleted = true;
    },

    progress(value) {
      if (value) this.showProgress(this.formatProgressText(value));
      else {
        this.closeProgress();
      }
    }
  }
};
</script>

<style scoped></style>
